import {Component, default as React} from "react";
import {Header} from "../components/Header";
import {ToastContainer} from "react-toastify";
import {Footer} from "../components/Footer";
import {CardsContent} from "../components/cards/CardsContent";
import {AssetsContent} from "../components/index/AssetsContent";
import {CardsInfoContent} from "../components/cards/CardsInfoContent";

export class CardsPage extends Component {
    render = () => (
        <>
            <div className="main__bg">
                <div className="main-box">
                    <Header />
                    <CardsInfoContent />
                </div>
            </div>

            <CardsContent/>

            <Footer isDark={true}/>
        </>
    );
}