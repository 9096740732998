import {Component, default as React} from "react";
import {Header} from "../components/Header";
import {CardsInfoContent} from "../components/cards/CardsInfoContent";
import {CardsContent} from "../components/cards/CardsContent";
import {Footer} from "../components/Footer";
import {AvailableContent} from "../components/available/AvailableContent";
import {AvailableMap} from "../components/available/AvailableMap";

export class AvailablePage extends Component {
    render = () => (
        <>
            <div className="main__bg">
                <div className="main__bg_wrapper">
                    <Header />
                    <AvailableContent />
                </div>
            </div>

            <AvailableMap />

            <Footer isDark={true}/>
        </>
    );
}