import * as React from "react";
import OptionsApiData from "../common/OptionsApiData";
import {ExchangeRate} from "./ExchangeRate";
import {Component} from "react";
import {ApiData} from "../../models/ApiData";

export default class ExchangeFiatRates extends Component {
    state = {
        data_list: [{}],
        selected_symbol: "BTC",
        loaded: false
    }
    public onChangeCurrency = (event: React.ChangeEvent<HTMLSelectElement>) => {
        var title = event.target.selectedOptions[0].text;
        this.loadSymbol(title);
    }

    public componentDidMount(): void {
        this.loadSymbol("BTC");
    };

    private loadSymbol(symbol:string)
    {
        ApiData.exchangeApiRequest({symbol: symbol}, 'get_rates_crypto_symbol').then((result) => {
            if(result.success)
                this.setState({
                    data_list: result.data,
                    selected_symbol: symbol,
                    loaded: true
                });
        });
    }



    public render = () => {
        return <>
            <div className="assets__currencies box box__currencies light">
                <div className="box__header d-md-flex align-items-center justify-content-between">
                    <span className="box__title">Supported Fiat Currencies</span>
                    <div className="price__currency float-lg-right">
                        <select name="#" id="#" className="box__select select_dark" onChange={this.onChangeCurrency} value={this.state.selected_symbol} >
                            <OptionsApiData type={'crypto'}  key_equals_value={true}/>
                        </select>
                    </div>
                </div>

                <div className="box__wrapper">
                    {this.state.loaded && this.state.data_list.map((e: any, index:number) => e.symbol == "RUB" ? '' : (<ExchangeRate key={index} from_cur={e.symbol ? e.symbol : ''} from_cur_title={e.title} to_cur={this.state.selected_symbol} rate={e.rate}/>))}
                    <div className="box-row d-flex align-items-center">
                        <div className="box-row__cur d-flex align-items-center">
                            <img src="img/currency/usd-w.svg" alt="p" className="assets-row__img" />
                            <img src="img/currency/c$.svg" alt="c$"
                                 className="assets-row__img assets-row__img_margin" />
                        </div>
                        <div className="box-row__icon box-row__icon_soon">
                            <img src="img/coming_soon.svg" alt="change" className="box-row__icon_change" />
                        </div>
                        <div className="box-row__cur">
                            <span className="box-row__name">Coming soon</span>
                        </div>
                    </div>
                </div>

            </div>
        </>
    }
}
