import * as React from "react";

import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {ExchangeContent} from "../components/index/ExchangeContent";
import {AssetsContent} from "../components/index/AssetsContent";
import {IndexContent} from "../components/index/IndexContent";


export const IndexPage = () =>
    <>
    <div className="main__bg">
        <div className="exchange">
            <Header />
            <ExchangeContent />
        </div>
        <AssetsContent />
    </div>
    <IndexContent/>
    <Footer isDark={true}/>
    </>

