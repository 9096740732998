import {Component, default as React} from "react";
import AvailableInCountry from "../index/AvailableInCountry";

export class CardsInfoContent extends Component {
    render = () => (
        <div className="container light">
            <div className="row">
                <div className="col-lg-6">
                    <div className="main-cards__content text-lg-left text-center">
                        <h2 className="title title__cards">Get A Prepaid<br /> Bitcoin Card For<br /> Your
                            Everyday<br /> Purchases</h2>
                        <p className="main-cards__text">You can have an absolute control of your Bitcoin expenses and
                            incomes with reloadable cryptocurrency card offered by CoinCheaper. Isn’t it nice? Also please
                            note that the cards are accepted everywhere so you will always have money in hand. </p>
                        <div className="main-cards__bottom">
                            <a href="login" className="button button_bg_red main-cards__link">Notify me</a>
                            <AvailableInCountry />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 clearfix">
                    <img src="img/card-n-phone.png" alt="card-n-phone"
                         className="img-fluid main-cards__picture float-lg-right" />
                </div>
            </div>
        </div>
    );
}
