
export abstract class NumUtils {
    static getPseudoRandomInt = (seed: number, min: number, max: number) : number =>
    {
        let expseed = seed;
        let d = Math.sqrt(seed);
        if(expseed > 100)
            expseed = Math.sqrt(seed);

        let v = Math.abs(Math.exp(expseed) - Math.hypot(seed)) + min;

        while(v > max)
            v /= d;

        while(v < min)
            v += Math.min((max - v) / d, min);

        return Math.round(v);
    }
}