import * as React from "react";

import {ExchangeStepsLine} from "./ExchangeStepsLine";
import ExchangeSummaryDetails from "./ExchangeSummaryDetails";
import {ExchangeStartModel} from "../../models/ExchangeStartModel";
import {ApiData} from "../../models/ApiData";
import {ExchangeStep1Form} from "./ExchangeStep1Form";
import {StringUtils} from "../../utils/StringUtils";
import {ExClient} from "../../models/ExClient";
import {ACTIONS, ExchangeSession} from "../../models/ExchangeSession";
import {toast} from "react-toastify";
import {Modal} from "../Modal";
import {UrlUtils} from "../../utils/UrlUtils";




export class  ExchangeStep1DetailsContent  extends ExchangeStartModel {
    private email_modal: React.RefObject<Modal> = React.createRef();
    private check_verify_email_timer: any;

    public componentDidMount(): void {
        ExchangeSession.redirectIfWrongStep("1");

        this.checkAuth();

        let data = ExchangeSession.getExchangeState();

        const state = data ? data : {fiat_amount: 250} ;
        let cryptoCurrency = UrlUtils.getUrlParam('to');
        if(cryptoCurrency)
            state["cryptoCurrency"] = cryptoCurrency;
        this.setState(state);
        this.loadData(state.fiat_amount);
        this.loadExchangeAmountLimit().then()

        let email = ExchangeSession.email_temp;
        if(email)
            $("#email").val(email);
    }


    startCheckVerifyEmail = async () =>
    {
        if(this.check_verify_email_timer)
            this.stopCheckVerifyEmail();

        if(await this.emailIsVerified()) {
            UrlUtils.redirectTo('exchange?step=2&email=' + ExchangeSession.email_temp);
            return;
        }
        else
            this.showEmailModal();

        this.check_verify_email_timer = setInterval(async () => {
            if(await this.emailIsVerified()) {
                this.stopCheckVerifyEmail();
                UrlUtils.redirectTo('exchange?step=2&email=' + ExchangeSession.email_temp);
            }
        }, 2500);
    }

    emailIsVerified = async () =>
    {
        const email = ExchangeSession.email_temp;
        if(StringUtils.isEmail(email))
        {
            let result = await ApiData.clientApiRequest({
                email: ExchangeSession.email_temp,
                s_id: ExClient.shop_id,
            }, "is_verified_temp_email");
            if(result.success && result.data) {
                return true;
            }
        }
        return false;
    }

    stopCheckVerifyEmail = () =>
    {
        if(this.check_verify_email_timer)
            clearInterval(this.check_verify_email_timer);
    }

    checkAuth = async () => {
        const is_authorized = await ExClient.checkLogin();
        if(is_authorized) {
            this.setState({is_authorized: true},
                () => this.setState({is_checking: false})
            );
        }
        else
        {
            this.setState({is_checking: false});
            if(ExchangeSession.action == ACTIONS.EmailVerifyExchange) {
                this.startCheckVerifyEmail();
            }
        }
    }

    onPaymentChange = () => this.setState({ is_payment_selected: true })

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        var data = $(event.currentTarget).serializeArray().reduce(function(obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});
        console.log("submit", event, data);
        data['step'] = 1;
        ExchangeSession.data = data;

        if (!this.state.is_payment_selected)
            return toast.error('Please select your preferred payment method.', { autoClose: 7000 })

        if(!data['agree_terms'])
            return toast.error("Please accept Terms & Conditions!");

        if(!data['agree_age'])
            return toast.error("Please accept that you are over 18 years old!");

        const { crypto_amount, cryptoCurrency, exchangeAmountLimit } = this.state
        const cryptoAmount = parseFloat(crypto_amount.toString()) || 0
        if (exchangeAmountLimit.hasOwnProperty(cryptoCurrency)) {
            const { min, max } = exchangeAmountLimit[cryptoCurrency]
            if (cryptoAmount < min || cryptoAmount > max)
                return toast.error(`Coins to Receive value should be greater or equal ${min} ${cryptoCurrency} and less than ${max} ${cryptoCurrency}`)
        } else if (cryptoAmount <= 0) {
            return toast.error(`Coins to Receive value should be greater than 0`)
        }

        if (data['fiat_input'] <= 0) {
            return toast.error(`Amount to Spend value should be greater than 0`)
        }

        if (this.state.fiatCurrency !== "USD" && this.state.fiatCurrency !== "EUR")
            return toast.error("Currently this direction is not available!");


        if (data['wallet'] !== undefined) {
            if (!StringUtils.isValidWallet(data['wallet'], data['crypto_select'])) {
                console.log("not valid wallet", data['crypto_select']);
                return toast.error("Please enter a valid wallet!");
            } else {
                let checkData = {
                    wallet: data['wallet'],
                    crypto_currency: data['crypto_select'],
                    fiat_amount: data['fiat_input'],
                    fiat_currency: data['fiat_select'],
                    shop_id: ExClient.shop_id
                };

                if(!await ExClient.isValidWallet(checkData))
                    return toast.error("Invalid wallet!");

                const is_pass_limits = await ApiData.exchangeApiRequest(checkData,'check_limits');
                if(is_pass_limits.success && !is_pass_limits.data)
                    return toast.error("Invalid amount. You have reached the limit of exchanges.");
            }
        }/* else {
            return toast.error("Please enter a valid wallet!");
        }*/

        if(!this.state.is_authorized)
        {
            if(!StringUtils.isEmail(String(data['email'])))
                return toast.error("Please enter a valid email!");
            else {
                this.checkIsRegisteredAndRedirectToStep2(data);
                return;
            }
        }
        else {
            ExchangeSession.saveDataParam("crypto_input",  this.state.crypto_amount.toString());
        }

        data['step'] = this.state.is_authorized ? 3 : 2;
        ExchangeSession.saveDataParam("step", data['step']);
        UrlUtils.redirectTo("exchange?step=" + (data['step']));
    }

    async checkIsRegisteredAndRedirectToStep2(data: any)
    {
        let result = await ApiData.clientApiRequest({email: data['email']}, 'is_registered');

        ExchangeSession.email_temp = data['email'];
        let exchange_data = ExchangeSession.data;
        exchange_data['crypto_input']  = this.state.crypto_amount;
        if(result.success && result.data)
        {
            const isRef = ExClient.shop_id || UrlUtils.getUrlParam('s_id')? true: false;

            exchange_data['step'] = isRef ? "1" : "3";
            ExchangeSession.action = ACTIONS.Exchange;
            ExchangeSession.data = exchange_data;
            UrlUtils.redirectTo("login?email=" + data['email']);
        }
        else
        {
            data['g-recaptcha-response'] = $('[name="g-recaptcha-response"]').val();

            if(!data['g-recaptcha-response']) {
                toast.error("Please fill out the reCAPTCHA!");
                return;
            }

            let exchange_data = ExchangeSession.data;
            if(ExClient.shop_id && ExClient.payment_id)
            {
                exchange_data['p_id'] = ExClient.payment_id;
                exchange_data['s_id'] = ExClient.shop_id;
            }

            const verify_email_sent_result = await ExClient.verifyTempMail(exchange_data);

            if(verify_email_sent_result.success) {

                exchange_data['step'] = "1";
                ExchangeSession.action = ACTIONS.EmailVerifyExchange;
                ExchangeSession.data = exchange_data;
                this.startCheckVerifyEmail();
            }
            else
                toast.error(verify_email_sent_result.message ? verify_email_sent_result.message : "Internal error. Please try again later or contact support.")
        }
    }

    showEmailModal = () =>
    {
        this.setState({temp_email: ExchangeSession.email_temp});
        this.email_modal.current.toggle();
    }

    componentWillUnmount(): void {
        this.stopCheckVerifyEmail();
    }

    onRateUpdate = (crypto_amount: any) => {
        console.log("RATE UPD", this);
        ApiData.getCurrenciesExchange().then((data) => {
            console.log(data);
            this.setState({
                crypto_prices: data.crypto_prices,
                crypto_amount: crypto_amount
            });
            ExchangeSession.saveDataParam("crypto_input", crypto_amount)
        });
    }




    public render()
    {
        return(
            <>
                <div className="body__steps">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 steps__dots_bg">
                                <ExchangeStepsLine step_id={0} />
                            </div>
                            <div className="col-lg-10">
                                <div className="steps__box steps__box_boxing">
                                    <div className="row no-gutters">
                                        <div className="steps__part_big">
                                            <div className="steps__box_light">
                                                <ExchangeStep1Form is_loading={this.state.is_loading || this.state.is_checking} fiatCurrency={this.state.fiatCurrency}
                                                                   cryptoCurrency={this.state.cryptoCurrency}
                                                                   fiat_amount={this.state.fiat_amount}
                                                                   crypto_amount={this.state.crypto_amount}
                                                                   is_authorized={this.state.is_authorized}
                                                                   onSubmit={this.onSubmit}
                                                                   onPaymentChange={this.onPaymentChange}
                                                                   onInputFiat={this.onInputFiat} onInputCrypto={this.onInputCrypto} onChangeFiat={this.onChangeFiat} onChangeCrypto={this.onChangeCrypto}/>
                                            </div>
                                        </div>
                                        <div className="steps__part_small">
                                            <div className="steps__box_dark">
                                                <ExchangeSummaryDetails  fiat_amount={this.state.fiat_amount} crypto_currency={this.state.cryptoCurrency} fiat_currency={this.state.fiatCurrency} onRateUpdate={this.onRateUpdate}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal ref={this.email_modal} id={"verify_email_modal"} footer_text={"To continue confirm your email first."}>
                    <img src="img/03.svg" alt="icons" className="modal__icon" />
                    <h3 className="modal__title">Verification email was sent</h3>
                    <p className="modal__text">Email was sent to <strong className="user_email">{this.state.temp_email}</strong> with the confirmation link. Follow the link to be redirected to CoinCheaper. </p>
                </Modal>
            </>
        )

    }
}

