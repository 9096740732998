import * as React from "react";
import {ToastContainer} from "react-toastify";
import {Header} from "../components/Header";
import {AffiliateInfoContent} from "../components/affiliate/AffiliateInfoContent";
import {AffiliateContent} from "../components/affiliate/AffiliateContent";
import AffiliateSignUp from "../components/affiliate/AffiliateSignUp";
import {Footer} from "../components/Footer";
import {AboutInfoContent} from "../components/about/AboutInfoContent";
import {AboutContent} from "../components/about/AboutContent";

export const AboutPage =  () => {
    return (
        <>
            <ToastContainer autoClose={6000}/>
            <div className="main__bg">
                <div className="main-box main-about">
                    <Header />
                    <AboutInfoContent />
                </div>
            </div>

            <AboutContent/>

            <Footer isDark={true}/>
        </>
    );
}