import * as React from 'react';

export interface CurrencyInputProps {
    amount: number;
    type: string,
    onInputAmount: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default class CurrencyInput extends React.Component<CurrencyInputProps, {}>  {
    public render = () => {
        return <input name={this.props.type + '_input'} type="text" className="input input_font_big" value={this.props.amount} onChange={this.props.onInputAmount}/>
    }
}