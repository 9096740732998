import {Component, default as React} from "react";
import {NumUtils} from "../../utils/NumUtils";
import {ApiData} from "../../models/ApiData";
import {ExchangeRate} from "./ExchangeRate";

export class JustExchangedBlock extends Component {
    state = {
        data_list: [{}],
        loaded: false
    }

    componentDidMount() {
        ApiData.exchangeApiRequest({}, 'get_last_exchanges').then((result) => {
            if(result.success)
                this.setState({
                    data_list: result.data,
                    loaded: true
                });
        });
    }

    render = () => (
        <div className="col-lg-6">
            <div className="box box__currencies light">
                <div className="box__header d-md-flex align-items-center justify-content-between">
                    <span className="box__title">Just Exchanged</span>
                    <span className="box__total float-lg-right">Total {NumUtils.getPseudoRandomInt(new Date().getDay(), 50, 89)} transactions</span>
                </div>
                <div className="box__wrapper">
                    {this.state.loaded && this.state.data_list.map((e: any, index:number) => (<ExchangeRate key={index} from_rate={e.fiat_amount} from_cur={e.fiat_currency} hide_from_cur={true} from_cur_title={e.fiat_title} to_cur={e.crypto_currency} rate={e.crypto_amount}/>))}

                    <div className="box__footer">
                        <hr className="box__hr" />
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <span className="box__title">Total volume in USD</span>
                        </div>
                        <div className="col-lg-6">
                            <span className="box-row__summ box__footer_summ">{NumUtils.getPseudoRandomInt(new Date().getDay(), 58195, 89634).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}