import {Component, default as React} from "react";
import {Header} from "../components/Header";
import {CardsInfoContent} from "../components/cards/CardsInfoContent";
import {CardsContent} from "../components/cards/CardsContent";
import {Footer} from "../components/Footer";
import {AffiliateInfoContent} from "../components/affiliate/AffiliateInfoContent";
import {AffiliateContent} from "../components/affiliate/AffiliateContent";
import AffiliateSignUp from "../components/affiliate/AffiliateSignUp";
import {ToastContainer} from "react-toastify";

export const AffiliatePage = () => {
    return (
        <>
            <ToastContainer autoClose={6000}/>
            <div className="main__bg">
                <div className="main-box">
                    <Header />
                    <AffiliateInfoContent />
                </div>
            </div>

            <AffiliateContent/>
            {AffiliateSignUp()}

            <Footer isDark={true}/>
        </>
    );
}