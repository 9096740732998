import * as React from "react";

export const AffiliateInfoContent = () => {
    return (

        <div className="main-aff__content light text-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="title text-center title__main-aff">You Can Make Money <br />With CoinCheaper</h2>
                        <p className="slogan slogan__main-aff"> Ready to start? Welcome!</p>
                        <a href="#signup" className="button button_bg_red main-aff__butt">GET STARTED</a>
                    </div>
                </div>
            </div>
            <div className="aff__marker">
                <div className="container">
                    <div className="row aff__marker_dots">
                        <div className="col-lg-3">
                            <div className="aff__marker_item text-center">
                                <div className="aff__marker_ic aff__marker_ic_1">
                                    <img src="img/affiliate/icons/step_1.svg" alt="icons" />
                                </div>
                                <h3 className="aff__marker_caption">Create account</h3>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="aff__marker_item text-center">
                                <div className="aff__marker_ic aff__marker_ic_2">
                                    <img src="img/affiliate/icons/step_1.svg" alt="icons" />
                                </div>
                                <h3 className="aff__marker_caption">Get marketing materials</h3>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="aff__marker_item text-center">
                                <div className="aff__marker_ic aff__marker_ic_3">
                                    <img src="img/affiliate/icons/step_1.svg" alt="icons" />
                                </div>
                                <h3 className="aff__marker_caption">Bring partners</h3>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="aff__marker_item text-center">
                                <div className="aff__marker_ic aff__marker_ic_4">
                                    <img src="img/affiliate/icons/step_1.svg" alt="icons" />
                                </div>
                                <h3 className="aff__marker_caption">Get 20% partners</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}