import * as React from "react";
import ExchangeBlock from "./ExchangeBlock";
import {Component} from "react";
import {NumUtils} from "../../utils/NumUtils";
import {JustExchangedBlock} from "./JustExchangedBlock";
import {WhyUsBlock} from "./WhyUsBlock";
import {QuestionsBlock} from "./QuestionsBlock";
import {BuyInfoBlock} from "./BuyInfoBlock";
import {GetBtcBlock} from "./GetBtcBlock";

export class IndexContent  extends Component
{
    render = () => (

    <>
        <BuyInfoBlock />

        <GetBtcBlock />


        <section className="map light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="text-center map__title title">Buy Bitcoin around <br />the world with CoinCheaper</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <img src="img/map.png?1" alt="map" className="map__img img-fluid" />
                    </div>
                </div>
            </div>
        </section>


        <WhyUsBlock />

        <QuestionsBlock />


        <section className="get-exchange">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 light">
                        <div className="exchange__text">
                            <h2 className="exchange__title title">Buy Bitcoins right now using any payment method</h2>
                            <p className="exchange__slogan slogan">CoinCheaper changes the rules.<br /> Get your coins at once!</p>
                        </div>
                    </div>
                    <div className="col-lg-6 bg__dots">
                        <ExchangeBlock />
                    </div>
                </div>
            </div>
        </section>

    </>
    );
}