import * as React from 'react';
import {Component} from "react";
import CurrencyInput from "../common/CurrencyInput";
import {ApiData} from "../../models/ApiData";
import {ExchangeStartModel} from "../../models/ExchangeStartModel";
import {ExchangeSession} from "../../models/ExchangeSession";
import {toast} from "react-toastify";
import {StringUtils} from "../../utils/StringUtils";
import {UrlUtils} from "../../utils/UrlUtils";
import OptionsApiData from "../common/OptionsApiData";
import {CurrencySelect} from "../common/CurrencySelect";
import {Timer} from "../common/Timer";
import {ExClient} from "../../models/ExClient";

export default class ExchangeBlock extends ExchangeStartModel {
    public componentDidMount(): void {
        this.loadData();
    };

    onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        var data = $(event.currentTarget).serializeArray().reduce(function(obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});
        console.log("submit", event, data);

        data['step'] = 1;
        ExchangeSession.data = data;

        UrlUtils.redirectTo("exchange?step=" + (data['step']));
    }

    onUpdateTimer = () =>
    {
        this.loadData(this.state.fiat_amount);
    }

    public render = () => {
        return <>
            <form className="clearfix" onSubmit={this.onSubmit}>
                <div className="exchange-form__wrapper float-lg-right">
                    <div className="exchange-form box" style={{display: this.state.is_loading ? 'none' : 'table' }}>
                        <span className="exchange-form__title box__title">What do you want to change?</span>
                        <p className="exchange-form__labels labels">Amount to Spend</p>
                        <div className="row flex-nowrap">
                            <div className="col-sm-8">
                                <CurrencyInput type={"fiat"} amount={this.state.fiat_amount}  onInputAmount={this.onInputFiat} />
                            </div>
                            <div className="col-sm-4 exchange-form_mob">
                                <CurrencySelect type={"fiat"} param={ExClient.payment_id} currency={this.state.fiatCurrency}  onChangeCurrency={this.onChangeFiat} />
                            </div>
                        </div>
                        <p className="exchange-form__labels labels">Coins to Receive</p>
                        <div className="row flex-nowrap">
                            <div className="col-sm-8">
                                <CurrencyInput type={"crypto"} onInputAmount={this.onInputCrypto} amount={this.state.crypto_amount}/>
                            </div>
                            <div className="col-sm-4 exchange-form_mob">
                                <CurrencySelect type={"crypto"} onChangeCurrency={this.onChangeCrypto}  currency={this.state.cryptoCurrency}/>
                            </div>
                        </div>

                        <div className="exchange-form__footer d-md-flex align-items-center">
                            <button className="button button_bg_red exchange-form__submit">Buy coins</button>
                            <div className="exchange-form__text">
                                <Timer count_down={60} onUpdate={this.onUpdateTimer} text={"Recalculated in"} className={"exchange-form__text_time"}/>
                                <a href="#" className="exchange-form__text_question" data-html="true" data-toggle="popover" title="Note <a href='#' class='close' data-dismiss='alert'><img src='img/icons/close_d.svg'</a>" data-content="We monitor the live price changes at a couple of the largest trading platforms. Our cryptocurrency price is determined by an average value of the most recent trade values on the market.">How is the price calculated?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    }
}