import {Component, default as React} from "react";
import OptionsApiData from "./OptionsApiData";

export interface CurrencySelectProps {
    currency: string;
    type: string,
    onChangeCurrency:  (event: React.ChangeEvent<HTMLSelectElement>) => void,
    param?: string
}

export class CurrencySelect extends Component<CurrencySelectProps> {
    render = () => (
        <select name={this.props.type + '_select'}  id="#" className="input input_font_big select_light" onChange={this.props.onChangeCurrency} value={this.props.currency}>
            <OptionsApiData type={this.props.type} param={this.props.param}  key_equals_value={true} />
        </select>
    );
}